<template>
  <div class="page">
    <div class="dkzc-box">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/index-mockExam' }"
          >我的模拟</el-breadcrumb-item
        >
        <el-breadcrumb-item
          :to="{
            path: '/index-mockExam-simulationHistory',
            query: { serial_code: $route.query.serial_code },
          }"
          >模拟历史</el-breadcrumb-item
        >
        <el-breadcrumb-item>模拟结果</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 二屏 -->
      <div class="testPaper" ref="wrap">
        <div class="left">
          <div class="left-one">
            <span class="title">试卷说明</span>
            <span class="total">总得分：</span>
            <span class="number">{{ detail.exam.fraction }}</span>
            <span>分</span>
          </div>
          <div class="left-two">
            <span>总题量：</span>
            <span class="two-number">{{ detail.item_count }}题</span>
            <span>考试时间：</span>
            <span class="two-number">{{ detail.total_minute }}分钟</span>
            <span>总分值：</span>
            <span class="two-number">{{ detail.total_score }}分</span>
          </div>
          <span class="prompt"
            >选择题（试卷题型分为单选题和多选题，请根据题型选择您认为正确的答案）</span
          >
          <!-- 左侧题 -->
          <div
            class="left-three"
            v-for="(item, index) in detail.question"
            :key="index"
            :id="'answerItem' + (index + 1)"
          >
            <span
              >{{ index + 1 }}.{{ item.question.caption }}（<span
                v-html="item.question.item_type == 6 ? '多' : '单'"
              ></span
              >选题）</span
            >
            <div class="three-question" v-if="item.question.item_type == 6">
              <div
                class="question"
                v-for="(item1, index1) in item.question.tk_option"
                :key="index1"
              >
                <!-- 多选已选 -->
                <img
                  :src="$http.baseURL + '/static/images/web/_12x.png'"
                  v-if="item.question.answer.indexOf(item1.id) > -1"
                />
                <!-- 多选未选 -->
                <img
                  :src="$http.baseURL + '/static/images/web/3772x.png'"
                  v-else
                />
                <span>{{ arr[index1] + "  " + item1.text }}</span>
                <!-- <span>试卷题型分为单选题和多选题，请根据题型选择您认为正确的答案试卷题型分为单选题和多选题，请根据题型选择您认为正确的答案试卷题型分为单选题和多选题，请根据题型选择您认为正确的答案试卷题型分为单选题和多选题，请根据题型选择您认为正确的答案</span> -->
              </div>
            </div>
            <div class="three-question" v-else>
              <div
                class="question"
                v-for="(item1, index1) in item.question.tk_option"
                :key="index1"
              >
                <!-- 单选已选 -->
                <img
                  :src="$http.baseURL + '/static/images/web/2x_2.png'"
                  v-if="item.question.check == item1.id"
                />
                <!-- 单选未选 -->
                <img
                  :src="$http.baseURL + '/static/images/web/662x.png'"
                  v-else
                />
                <span>{{ arr[index1] + "  " + item1.text }}</span>
                <!-- <span>试卷题型分为单选题和多选题，请根据题型选择您认为正确的答案试卷题型分为单选题和多选题，请根据题型选择您认为正确的答案试卷题型分为单选题和多选题，请根据题型选择您认为正确的答案试卷题型分为单选题和多选题，请根据题型选择您认为正确的答案</span> -->
              </div>
            </div>
            <div
              class="answer"
              v-if="item.question.item_type == 6"
              :class="{ active: item.question.check != item.question.answer }"
            >
              <span>选择：</span>
              <span class="answer-number" v-if="item.question.check"
                ><span
                  v-for="(el, i) in item.question.arr"
                  :key="i"
                  class="span"
                  >{{ arr[el] }}</span
                ></span
              >
              <span v-else>未选择</span>
              <span>正确答案：</span>
              <span
                v-for="(ele, ii) in item.question.arr1"
                :key="ii"
                class="span"
                >{{ arr[ele] }}</span
              >
            </div>
            <div
              class="answer"
              v-else
              :class="{ active: item.question.check != item.question.answer }"
            >
              <span>选择：</span>
              <span class="answer-number">{{
                item.question.check ? arr[item.question.index] : "未选择"
              }}</span>
              <span>正确答案：</span>
              <span>{{ arr[item.question.index1] }}</span>
            </div>
          </div>
        </div>
        <!-- 右侧答题卡 -->
        <div class="right">
          <div class="right-one">
            <span>答对</span>
            <span class="one-number">{{ detail.exam.correct_num }}</span>
            <span>答错</span>
            <span>{{ detail.exam.error_num }}</span>
          </div>
          <div class="right-two">
            <div class="green"></div>
            <span>答对</span>
            <div class="green"></div>
            <span>答错</span>
          </div>
          <div class="right-three">
            <div
              class="question"
              :class="{ active: item.question.check == item.question.answer }"
              v-for="(item, index) in detail.question"
              :key="index"
              @click="getLocation(index + 1)"
            >
              <span>{{ index + 1 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-backtop :right="450">答题卡</el-backtop>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: {
        exam: {},
      },
      arr: ["A", "B", "C", "D", "E", "F", "G"],
    };
  },
  async mounted() {
    this.xin.isLoadding(true);
    await this.getExamInfo(
      this.$route.query.serial_code,
      this.$route.query.exam_id
    );
    this.xin.isLoadding(false);
  },
  methods: {
    getLocation(index) {
      let id = "answerItem" + index;
      window.scrollTo({
        top:
          document.getElementById(id).offsetTop +
          this.$refs.wrap.offsetTop -
          15,
        behavior: "smooth",
      });
    },
    async getExamInfo(serial_code, exam_id) {
      let res = await this.$newsApi.getExamInfo({
        serial_code: serial_code,
        exam_id: exam_id,
      });
      if (res.code == 200) {
        let data = res.data;
        for (let i in data.answer) {
          for (let j in data.question) {
            if (i == data.question[j].question.id) {
              data.question[j].question.check = data.answer[i];
              data.question[j].question.arr = [];
              data.question[j].question.arr1 = [];
              for (let z in data.question[j].question.tk_option) {
                if (
                  data.question[j].question.item_type == 6 &&
                  data.question[j].question.check.length
                ) {
                  //多选题
                  if (
                    data.question[j].question.check.indexOf(
                      data.question[j].question.tk_option[z].id
                    ) > -1
                  ) {
                    data.question[j].question.arr.push(z);
                  }
                  if (
                    data.question[j].question.answer.indexOf(
                      data.question[j].question.tk_option[z].id
                    ) > -1
                  ) {
                    data.question[j].question.arr1.push(z);
                  }
                } else {
                  //单选题
                  if (
                    data.question[j].question.tk_option[z].id ==
                    data.question[j].question.answer
                  ) {
                    data.question[j].question.index1 = z;
                  }
                  if (
                    data.question[j].question.tk_option[z].id ==
                    data.question[j].question.check
                  ) {
                    data.question[j].question.index = z;
                  }
                }
              }
            }
          }
        }
        this.detail = data;
      } else {
        this.xin.message(res.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  ::v-deep .el-checkbox__label {
    white-space: normal;
    word-break: break-all;
  }
  ::v-deep .el-radio__label {
    white-space: normal;
    word-break: break-all;
  }
  ::v-deep .el-radio {
    margin-top: 10px;
  }
  ::v-deep .el-checkbox {
    margin-top: 10px;
  }
  ::v-deep .el-backtop {
    width: 100px;
    color: #fff;
    border-radius: 5px;
    background: $--themeColor;
  }
  background: #f2f2f2;
  > .dkzc-box {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;
    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-right: 10px;
    }
    > .big {
      margin: 0 10px;
    }
    > .simulationResult {
      margin-top: 3px;
    }
    > .testPaper {
      display: flex;
      flex-direction: row;
      > .left {
        display: flex;
        flex-direction: column;
        width: 850px;
        background: #ffffff;
        padding: 30px;
        box-sizing: border-box;
        font-size: 18px;
        color: #333333;
        > .left-one {
          color: #8f8f8f;
          margin-bottom: 10px;
          > .title {
            font-size: 24px;
            color: #333333;
          }
          > .total {
            margin-left: 559px;
          }
          > .number {
            color: #e60012;
            font-size: 36px;
          }
        }
        > .left-two {
          font-size: 14px;
          margin-bottom: 10px;
          > .two-number {
            margin-right: 60px;
          }
        }
        > .prompt {
          margin-bottom: 30px;
        }
        > .left-three {
          > span {
            line-height: 25px;
          }
          display: flex;
          flex-direction: column;
          margin-top: 40px;
          &:nth-child(4) {
            margin-top: 0;
          }
          > .three-question {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            img {
              width: 18px;
              height: 18px;
            }
            > .question {
              font-size: 14px;
              margin-top: 20px;
              display: flex;
              flex-direction: row;
              align-items: center;
              > .round {
                width: 18px;
                height: 18px;
                background: #ffffff;
                border: 1px solid #333333;
                border-radius: 50%;
              }
              > span {
                margin: 0 50px 0 10px;
                line-height: 20px;
              }
            }
          }
          > .answer {
            display: flex;
            flex-direction: row;
            margin-top: 20px;
            &.active {
              color: #e60012;
            }
            .answer-number {
              margin-right: 30px;
            }
            .span {
              margin-right: 5px;
            }
          }
        }
      }
      > .right {
        display: flex;
        flex-direction: column;
        width: 310px;
        background: #ffffff;
        padding: 30px 20px;
        box-sizing: border-box;
        margin-left: auto;
        height: max-content; //包裹自己的元素
        > .right-one {
          display: flex;
          flex-direction: row;
          font-size: 24px;
          color: #333333;
          > .one-number {
            margin-right: 30px;
          }
        }
        > .right-two {
          display: flex;
          flex-direction: row;
          margin: 19px 0;
          font-size: 12px;
          > .green {
            &:nth-child(3) {
              background: #e60012;
              margin-left: 15px;
            }
            width: 12px;
            height: 12px;
            background: $--themeColor;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
        > .right-three {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          > .question {
            width: calc(100% / 6);
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.active {
              > span {
                background: $--themeColor;
                border: 1px solid $--themeColor;
                color: #fff;
              }
            }
            > span {
              color: #fff;
              background: #e60012;
              border: 1px solid #e60012;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              font-size: 16px;
              text-align: center;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
